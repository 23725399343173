import { Box, HStack, Icon, IconButton, Stack, Tag, Textarea } from "@chakra-ui/react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useKey } from "react-use";
import { useAutosizeTextArea } from "../../../lib/hooks/chat/useAutosizeTextarea.ts";
import { useSendChatMessageMutation } from "../../../lib/mutations/useSendChatMessageMutation.ts";
import { ChatContextBar } from "../chatContext/ChatContextBar.tsx";

interface FormInput {
    content: string;
    selectedAssetIds: number[];
}
interface ChatInputProps {
    characterLimit?: number;
    activeSessionId: number;
    caseId: number;
}

/**
 * @todo: this component shouldn't be sending messages directly, but rather use callbacks to do so, same for tracking
 * input blocked state
 * @param activeSessionId - The session id to send the message to
 * @param characterLimit - The character limit for the input
 */
export const ChatInput: FC<ChatInputProps> = ({ activeSessionId, caseId, characterLimit = 2000 }) => {
    // Form setup
    const { register, handleSubmit, reset, watch, setValue } = useForm<FormInput>({
        defaultValues: { content: "", selectedAssetIds: [] },
    });

    // Because we already need ref for autosizing, we do a bit of black magic here
    const { ref, ...registerContent } = register("content", {
        maxLength: { value: characterLimit, message: `Max length is ${characterLimit}` },
        minLength: { value: 1, message: "Min length is 1" },
    });

    // Autosize the textarea
    const textareaRef = useRef<HTMLTextAreaElement>();
    useAutosizeTextArea(textareaRef.current, watch("content"));

    // Blocking input when the bot is typing
    const [inputBlocked, setInputBlocked] = useState(false);

    // Sending messages
    const { mutate: sendChatMessage, isLoading: isSendingChatMessage } = useSendChatMessageMutation(setInputBlocked);
    const onSubmit = handleSubmit(async (data) => {
        if (!activeSessionId) return;
        if (!data.content) return;
        if (inputBlocked) return;
        if (isSendingChatMessage) return;
        setValue("content", "");
        sendChatMessage({
            sessionId: activeSessionId,
            content: data.content,
            assetIds: data.selectedAssetIds,
        });
    });

    // Holding shift to allow new lines for textarea OR to send the message
    const shiftPressed = useRef(false);
    useKey("Shift", () => (shiftPressed.current = true), { event: "keydown" });
    useKey("Shift", () => (shiftPressed.current = false), { event: "keyup" });
    useKey(
        "Enter",
        async (e) => {
            if (shiftPressed.current) return; // Shift down? Simply allow the newline
            e.preventDefault(); // Otherwise (shift is down), prevent the newline ..
            await onSubmit();
        },
        {},
        [activeSessionId, onSubmit, isSendingChatMessage],
    );

    // Background color for character counter / character limit
    let characterLimitBg = "purple.300";
    if (watch("content").length >= characterLimit - 100) characterLimitBg = "error.400";
    else if (watch("content").length >= characterLimit - 300) characterLimitBg = "warn.500";

    return (
        <Stack gap={1}>
            <ChatContextBar
                caseId={caseId}
                selectedAssetIds={watch("selectedAssetIds")}
                setSelectedAssetIds={(ids) => setValue("selectedAssetIds", ids)}
            />

            <HStack as={"form"} onSubmit={onSubmit}>
                <Box position={"relative"} w={"full"}>
                    <Textarea
                        maxLength={characterLimit}
                        variant={"solid"}
                        maxH={"14.5em"}
                        minH={0}
                        placeholder={"Ask anything"}
                        autoFocus
                        ref={(e) => {
                            if (e) textareaRef.current = e;
                            ref(e);
                        }}
                        {...registerContent}
                    />

                    <Tag
                        position={"absolute"}
                        bottom={0}
                        right={2}
                        transform={"translateY(50%)"}
                        w={"fit-content"}
                        color={"white"}
                        rounded={"full"}
                        fontWeight={"bold"}
                        transition={"background-color 0.3s"}
                        bg={characterLimitBg}
                        size={"sm"}
                    >
                        {characterLimit - watch("content").length}
                    </Tag>
                </Box>

                <IconButton
                    isDisabled={inputBlocked || isSendingChatMessage}
                    type={"submit"}
                    colorScheme={"purple"}
                    rounded={"xl"}
                    icon={<Icon as={FontAwesomeIcon} icon={faPaperPlane} />}
                    aria-label={"Send"}
                />
            </HStack>
        </Stack>
    );
};
