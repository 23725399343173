import { Center, HStack, Icon, IconButton } from "@chakra-ui/react";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai/react";
import { atomWithStorage } from "jotai/utils";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useNotificationsQuery } from "../../../lib/queries/useNotificationsQuery.ts";
import { SideNavContent } from "../sideNavContent/SideNavContent.tsx";

const expandedAtom = atomWithStorage<boolean>("expanded", true, undefined, {
    getOnInit: true,
});

interface SideNavProps {}
export const SideNav: FC<SideNavProps> = ({}) => {
    const [expanded, setExpanded] = useAtom(expandedAtom);
    const contentRadius = "40px"; // The width of the border radius of the page/sidebar

    const { caseId: caseIdStr } = useParams();
    const caseId = parseInt(caseIdStr || "");

    const { data: notifications } = useNotificationsQuery();
    const actuallyExpanded = expanded && !!caseId;

    return (
        <HStack
            mr={`-${contentRadius}`}
            gap="0"
            bg="black.500"
            h={"100dvh"}
            pos={"sticky"}
            top={0}
            overflow-y={"scroll"}
            zIndex={20}
        >
            {/* This is the content */}

            <SideNavContent expanded={actuallyExpanded} />

            <Center
                borderTopLeftRadius="1000"
                borderBottomLeftRadius="1000"
                alignSelf="stretch"
                w={contentRadius}
                bg="white"
            >
                {/* arrow button, only visible if a case is active */}
                {!!caseId && (
                    <IconButton
                        onClick={() => setExpanded(!actuallyExpanded)}
                        aria-label={"Toggle navigation"}
                        icon={<Icon as={FontAwesomeIcon} icon={actuallyExpanded ? faChevronLeft : faChevronRight} />}
                        borderBottomLeftRadius={0}
                        borderTopLeftRadius={0}
                        borderRadius={"xl"}
                        bg={"white"}
                        h={24}
                        w={"full"}
                        _hover={{ bg: "gray.400" }}
                    />
                )}
            </Center>
        </HStack>
    );
};
