import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { getGeneratedQuestions } from "../../sdk/functional/$case/questionnaires/questions/generated";
import { getAuthHeaders } from "../app/api.ts";
import { useReplaceQueriesItem } from "../util/tanstack/useReplaceQueriesItem.ts";

export interface GeneratedQuestion {
    question?: string;
    reasoning?: string;
}

export const useGenerateQuestionsMutation = (caseId: number, questionnaireId: number) => {
    const replaceQueriesItem = useReplaceQueriesItem();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            // const onMemoCreate = (memo: getMemo.Output) => {
            //     // console.log("onMemoCreate", memo);
            //     queryClient.setQueryData<getMemo.Output>(["memo", memo.case_id], () => memo);
            // };
            //
            // const onMemoAddText = (data: { caseId: number; topic: string; text: string }) => {
            //     // console.log("onMemoAddText", data);
            //     queryClient.setQueryData<getMemoTopics.Output>(["memo", data.caseId], (memo) => {
            //         if (!memo) return memo;
            //         return { ...memo, text: memo.text + data.text }; // Force a re-render by creating a new object
            //     });
            // };
            //
            // const onMemoComplete = (memo: getMemo.Output) => {
            //     // console.log("onMemoComplete", memo);
            //     queryClient.setQueryData<getMemo.Output>(["memo", memo.case_id], () => memo);
            // };
            //
            // const onTopicCreate = (topic: getMemoTopics.Output) => {
            //     // console.log("onTopicCreate", topic);
            //     queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", topic.case_id, topic.topic], () => topic);
            // };
            //
            // const onTopicAddText = (data: { caseId: number; topic: string; text: string }) => {
            //     // console.log("onTopicAddText", ["memoTopic", data.caseId, data.topic], data);
            //     queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", data.caseId, data.topic], (topic) => {
            //         if (!topic) return topic; // we didn't have it yet, but now we do
            //         return { ...topic, text: topic.text + data.text }; // Force a re-render by creating a new object
            //     });
            // };
            //
            // const onTopicComplete = (topic: getMemoTopics.Output) => {
            //     // console.log("onTopicComplete", topic);
            //     queryClient.setQueryData<getMemoTopics.Output>(["memoTopic", topic.case_id, topic.topic], () => topic);
            // };

            const onGeneratedQuestionUpdate = (question: getGeneratedQuestions.Output[0]) => {
                // console.log("Question updated:", question);
                replaceQueriesItem(question, ["generatedQuestions", caseId, questionnaireId]);
            };

            const onGeneratedQuestionAdd = (question: getGeneratedQuestions.Output[0]) => {
                console.log("Question added:", question.id);
                queryClient.setQueryData(
                    ["generatedQuestions", caseId, questionnaireId],
                    (data: (typeof question)[] | undefined) => {
                        if (!data) return data;
                        return produce(data, (draft) => {
                            draft.push(question);
                        });
                    },
                );
            };

            const onMessage = async (msg: EventSourceMessage) => {
                if (msg.event === "generatedQuestion.add") onGeneratedQuestionAdd(JSON.parse(msg.data));
                if (msg.event === "generatedQuestion.update") onGeneratedQuestionUpdate(JSON.parse(msg.data));
                if (msg.event === "error") throw new Error(msg.data);
            };

            await fetchEventSource(
                import.meta.env.VITE_API_BASE + `/case/${caseId}/questionnaires/${questionnaireId}/questions/generate`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", ...getAuthHeaders() },
                    onmessage: onMessage,
                    openWhenHidden: true, // critical so it doesnt re-post on tab focus
                    onerror: (err: unknown) => {
                        console.warn("error", err);
                        throw err; // Stop fetchEventSource from retrying
                    },
                },
            );
        },

        retry: false,
    });
};
