import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useDeletePendingQuestionsMutation = (caseId: number, questionnaireId: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await api.functional.$case.questionnaires.questions.deletePendingQuestions(
                getApiConnection(),
                caseId,
                questionnaireId,
            );
        },
        onSuccess: () => {
            // Invalidate generated questions query to refetch with updated read status
            queryClient.invalidateQueries({ queryKey: ["generatedQuestions", caseId, questionnaireId] });
        },
    });
};
