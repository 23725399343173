import {
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Spacer,
    Spinner,
    Stack,
} from "@chakra-ui/react";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { AnimatePresence, motion } from "motion/react";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import qnaPlaceholder from "../../assets/images/qna-placeholder.svg";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { ShareButton } from "../../components/members/shareButton/ShareButton.tsx";
import { EmptyState } from "../../components/misc/emptyState/EmptyState.tsx";
import { useCreateQuestionnareMutation } from "../../lib/mutations/useCreateQuestionnareMutation.ts";
import { useQuestionnairesQuery } from "../../lib/queries/useQuestionnairesQuery.ts";
import { getQuestionnaires } from "../../sdk/functional/$case/questionnaires";
import { Questionnaire } from "./components/Questionnaire.tsx";

interface Props {}
export const QuestionnairesPage: FC<Props> = ({}) => {
    const { caseId } = useParams<"caseId">();
    if (!caseId) throw new Error("No case id provided");

    const navigate = useNavigate();

    const $questionnaires = useQuestionnairesQuery(parseInt(caseId));

    const $createQuestionnaire = useCreateQuestionnareMutation();

    const createQuestionnaire = async () => {
        const questionnaire = await $createQuestionnaire.mutateAsync({ caseId: parseInt(caseId) });
        navigate(`/cases/${caseId}/questionnaires/${questionnaire.id}`);
    };

    const [query, setQuery] = useState("");

    const fuse = useMemo(
        () => new Fuse($questionnaires.data ?? ([] as getQuestionnaires.Output), { keys: ["name"] }),
        [$questionnaires.data],
    );
    const filteredQuestionnaires = query.length > 0 ? fuse.search(query).map((r) => r.item) : $questionnaires.data;

    return (
        <SideNavLayout>
            <Stack gap={10} flexGrow={1}>
                {/*title, share button*/}
                <HStack gap={4}>
                    <Heading as={"h1"} size={"lg"}>
                        Q&A
                    </Heading>
                    <Spacer />
                    <ShareButton variant={"full"} caseId={parseInt(caseId)} />
                </HStack>

                {/* search, new questionnaire button*/}
                <HStack gap={4}>
                    <InputGroup maxW={80} variant={"ghost"}>
                        <InputLeftElement pointerEvents="none">
                            <Icon icon={faMagnifyingGlass} as={FontAwesomeIcon} />
                        </InputLeftElement>
                        <Input variant={"gray"} placeholder={"Search Q&A"} onChange={(e) => setQuery(e.target.value)} />
                    </InputGroup>
                    <Spacer />
                    <Button
                        colorScheme={"purple"}
                        leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}
                        onClick={createQuestionnaire}
                        isLoading={$createQuestionnaire.isLoading}
                    >
                        New Q&A
                    </Button>
                </HStack>
                {$questionnaires.isLoading && <Spinner />}

                {/*questionnaires*/}
                {$questionnaires.isSuccess && filteredQuestionnaires?.length && (
                    <Box>
                        <AnimatePresence mode={"popLayout"}>
                            {filteredQuestionnaires.map((questionnaire, i) => (
                                <motion.div
                                    layout
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, scale: 0.95 }}
                                    transition={{ ease: "backOut", delay: i * 0.05 }}
                                    key={questionnaire.id}
                                    style={{ marginBottom: 20 }}
                                >
                                    <Questionnaire questionnaire={questionnaire} />
                                </motion.div>
                            ))}
                        </AnimatePresence>
                    </Box>
                )}

                {/* Empty state */}
                {/* No assets uploaded */}
                {$questionnaires.isSuccess && $questionnaires.data?.length === 0 && (
                    <EmptyState grow imageSrc={qnaPlaceholder}>
                        No Q&A added to this case
                    </EmptyState>
                )}

                {/* no search results */}
                {$questionnaires.isSuccess &&
                    $questionnaires.data?.length > 0 &&
                    filteredQuestionnaires?.length === 0 && (
                        <Center h="70vh">
                            <EmptyState>No questionnaires found</EmptyState>
                        </Center>
                    )}
            </Stack>
        </SideNavLayout>
    );
};
