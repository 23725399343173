import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useAssetUrlQuery = (caseId?: number, assetId?: number) => {
    return useQuery({
        queryKey: ["assetUrl", caseId, assetId],
        queryFn: async (): Promise<string> => {
            if (!caseId || !assetId) return "";
            const res = await sdk.functional.asset.$case.assets.url.downloadAsset(getApiConnection(), caseId, assetId);
            return res.url;
        },
        staleTime: 60_000, // 1 min
        retryOnMount: false,
    });
};
