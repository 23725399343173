import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useQuestionnaireQuery = (caseId: number, questionnaireId: number) => {
    return useQuery({
        queryKey: ["questionnaire", questionnaireId],
        queryFn: async () => {
            return await sdk.functional.$case.questionnaires.getQuestionnaire(
                getApiConnection(),
                caseId,
                questionnaireId,
            );
        },
    });
};
