import { InfiniteData, QueryKey, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

/**
 * Replaces items in Tanstack Query's cache
 * Mainly used to replace the items in the cache after a mutation, or as an optimistic update
 * You can then optionally still refresh the data for a single source of truth
 * Warning: there is no type checking here. Make sure the type you are replacing is the same as the one you are replacing with
 * Handles infinite queries out of the box
 * @todo: write tests
 */
export const useReplaceQueriesItem = () => {
    const queryClient = useQueryClient();

    /**
     * @param item - The item to replace the old item(s) with
     * @param queryKey - The (partial) query key to replace the items in
     * @param compareField - The field to compare the old item with the new item, defaults to 'id'
     * @param getArrayItems - If the target array is not the root, you need to provide a function that returns the array items
     * generic D represents data format, T represents one item
     */
    return <D, T extends { id?: unknown }>(
        item: T,
        queryKey: QueryKey,
        compareField: keyof T = "id",
        getArrayItems: (data: D) => T[] = (data: D) => data as T[],
    ) => {
        // @ts-expect-error todo
        queryClient.setQueriesData(queryKey, (data: D) => {
            if (!data) return data;

            const isSame = (oldItem: T) => oldItem[compareField] === item[compareField];

            // If we are dealing with an infinite query, we need to do this for each page
            if (typeof data === "object" && "pages" in data && Array.isArray(data.pages)) {
                // Return a new object so react knows to rerender
                return produce(data, (draft: InfiniteData<D>) => {
                    for (const page of draft.pages) {
                        const items = getArrayItems(page);
                        items.forEach((oldItem, i) => (isSame(oldItem) ? (items[i] = item) : null));
                    }
                });
            } else {
                // Not dealing with an infinite query, simply replace the items
                return produce(data, (draft: D) => {
                    const items = getArrayItems(draft as D);
                    items.forEach((oldItem, i) => (isSame(oldItem) ? (items[i] = item) : null));
                });
            }
        });
    };
};
