import { Card, CardBody, Checkbox, HStack, IconButton, Img, Text, Textarea, Tooltip, useToast } from "@chakra-ui/react";
import { FC, memo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAutosizeTextArea } from "../../../lib/hooks/chat/useAutosizeTextarea.ts";

interface FormData {
    question: string;
}

interface Props {
    question: string;
    reasoning?: string;
    index?: number; // 0 based
    isSelected?: boolean;
    onToggle?: () => void;
    onEdit?: (newQuestion: string) => void;
}
export const Question: FC<Props> = memo(({ index, question, reasoning, isSelected, onEdit, onToggle }) => {
    const textAreaRef = useRef<HTMLTextAreaElement | undefined>();
    const toast = useToast();
    const $form = useForm<FormData>({
        defaultValues: { question: question ?? "" },
    });
    useAutosizeTextArea(textAreaRef.current ?? undefined, $form.watch("question"));

    const onSubmit = async (data: FormData) => {
        if (!onEdit) return;
        if (data.question.trim().length < 3) {
            toast({
                title: "Question is too short",
                status: "error",
            });
            return;
        }
        onEdit(data.question);
        $form.reset();
        textAreaRef.current?.focus();
    };

    return (
        <Card size={"sm"} px={5} variant={"subtle"}>
            <CardBody as={"form"} onSubmit={$form.handleSubmit(onSubmit)}>
                <HStack>
                    {onToggle ? (
                        <Checkbox
                            size={"md"}
                            colorScheme={"purple"}
                            gap={3}
                            isChecked={isSelected}
                            onChange={onToggle}
                            flex={1}
                        >
                            {index !== undefined ? `${index + 1}. ` : ""}
                            <Text variant={"body2"}>{question}</Text>
                        </Checkbox>
                    ) : (
                        <>
                            <Text alignSelf={"start"} variant={"body2"}>
                                {index !== undefined ? `${index + 1}. ` : ""}
                            </Text>
                            <Textarea
                                p={0}
                                flex={1}
                                variant={"unstyled"}
                                resize={"none"}
                                placeholder={"Add a question.."}
                                {...$form.register("question", {
                                    required: true,
                                    minLength: 3,
                                })}
                                disabled={$form.formState.isSubmitting || !onEdit}
                                ref={(e) => {
                                    if (e) textAreaRef.current = e;
                                    $form.register("question").ref(e);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key !== "Enter") return;
                                    e.preventDefault();
                                    $form.handleSubmit(onSubmit)();
                                }}
                                minH={0}
                                noOfLines={1}
                            />
                        </>
                    )}

                    {reasoning && (
                        <>
                            <Tooltip label={reasoning} placement={"top"} hasArrow>
                                <IconButton
                                    variant={"dropdown"}
                                    borderRadius={"lg"}
                                    size={"xs"}
                                    aria-label={"Reasoning"}
                                    icon={<Img src={"/favicon.svg"} alt={"Reasoning"} boxSize={3} />}
                                />
                            </Tooltip>
                        </>
                    )}
                </HStack>
            </CardBody>
        </Card>
    );
});
