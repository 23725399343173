import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useGeneratedQuestionsQuery = (caseId: number, questionnaireId: number) => {
    return useQuery({
        queryKey: ["generatedQuestions", caseId, questionnaireId],
        queryFn: async () => {
            return await sdk.functional.$case.questionnaires.questions.generated.getGeneratedQuestions(
                getApiConnection(),
                questionnaireId,
                caseId,
            );
        },
    });
};
