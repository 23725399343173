import {
    Button,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Tag,
    TagLabel,
    useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { GetMessagesDto } from "../../../sdk/structures/GetMessagesDto.ts";
import { Context } from "./Context.tsx";

interface Props {
    caseId: number;
    contexts: GetMessagesDto["contexts"];
}

/**
 * Renders the contexts used for a chat message in a horizontal stack
 * Shows up to X contexts, and a popover with the rest
 * @param caseId
 * @param contexts
 */
export const ChatMessageContext: FC<Props> = ({ caseId, contexts }) => {
    const popoverDisclosure = useDisclosure();

    const maxVisibleContexts = 2;
    const firstTwoContexts = contexts.slice(0, maxVisibleContexts);
    const restContexts = contexts.slice(maxVisibleContexts);

    return (
        <HStack>
            {/* Show first two contexts */}
            {firstTwoContexts.map((context) => {
                return <Context asLink key={context.id} asset={context.asset} />;
            })}

            {/* Show popover if there are more contexts */}
            {restContexts.length > 0 && (
                <Popover
                    isOpen={popoverDisclosure.isOpen}
                    onClose={popoverDisclosure.onClose}
                    onOpen={popoverDisclosure.onOpen}
                >
                    <PopoverTrigger>
                        {/* Button to show popover, styled to look like a tag */}
                        <Button variant={"unstyled"}>
                            <Tag variant={"asset"} size={"md"} _hover={{ bg: "gray.400" }}>
                                <TagLabel>+{restContexts.length} more</TagLabel>
                            </Tag>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <Stack align={"start"} gap={3}>
                                {restContexts.map((context) => {
                                    return <Context key={context.id} asLink asset={context.asset} />;
                                })}
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            )}
        </HStack>
    );
};
