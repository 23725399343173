import { HStack, Tag, TagLabel } from "@chakra-ui/react";
import { FC } from "react";
import { useAssetsQuery } from "../../../lib/queries/useAssetsQuery.ts";
import { ChatContextPopover } from "./ChatContextPopover.tsx";
import { Context } from "./Context.tsx";

interface Props {
    caseId: number;
    selectedAssetIds: number[];
    setSelectedAssetIds: (ids: number[]) => void;
}

export const ChatContextBar: FC<Props> = ({ caseId, selectedAssetIds, setSelectedAssetIds }) => {
    const $assets = useAssetsQuery(caseId);
    const selectedAssets = $assets.data?.filter((asset) => selectedAssetIds.includes(asset.id)) ?? [];

    const removeAsset = (assetId: number) => {
        setSelectedAssetIds(selectedAssetIds.filter((id) => id !== assetId));
    };

    const reset = () => {
        setSelectedAssetIds([]);
    };

    return (
        <HStack>
            {/*Old button, keeping until we have UX fixes*/}
            {/*<Button*/}
            {/*    variant={"ghost"}*/}
            {/*    color={"gray.600"}*/}
            {/*    borderRadius={"lg"}*/}
            {/*    size={"sm"}*/}
            {/*    // rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} />}*/}
            {/*    leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}*/}
            {/*>*/}
            {/*    /!*<Text variant={"labelCaps"}>Add assets</Text>*!/*/}
            {/*    Add assets*/}
            {/*    /!*{selectedAssets.length === 0 && (*!/*/}
            {/*    /!*    <Text color={"black.500"} variant={"labelBold"}>*!/*/}
            {/*    /!*        All*!/*/}
            {/*    /!*    </Text>*!/*/}
            {/*    /!*)}*!/*/}
            {/*</Button>*/}

            <ChatContextPopover
                caseId={caseId}
                selectedAssetIds={selectedAssetIds}
                setSelectedAssetIds={setSelectedAssetIds}
                onClear={() => reset()}
            />

            {/*Selected assets*/}
            {selectedAssets.length > 0 && (
                <HStack>
                    {selectedAssets.map((asset) => (
                        <Context asset={asset} key={asset.id} onRemove={() => removeAsset(asset.id)} />
                    ))}
                </HStack>
            )}

            {/*All assets selected (same as none)*/}
            {selectedAssets.length === 0 && (
                <Tag variant={"asset"} size={"md"}>
                    <TagLabel color={"gray.500"}>All assets are included for response</TagLabel>
                </Tag>
            )}
        </HStack>
    );
};
