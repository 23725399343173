import { Avatar, Button, HStack, Icon, Skeleton, Spacer, Stack, Text } from "@chakra-ui/react";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import MaeveAvatar from "../../../assets/logos/maeve-avatar.svg";
import { aggregateSources } from "../../../lib/chat/aggregateSources.ts";
import { getMessages } from "../../../sdk/functional/bot/sessions/messages";
import { CopyToClipboard } from "../../misc/copyToClipboard/CopyToClipboard.tsx";
import { StyledMarkdown } from "../../misc/styledMarkdown/StyledMarkdown.tsx";
import { SourcesPopover } from "../../rag/sources/SourcePopover/SourcePopover.tsx";
import { ChatMessageContext } from "../chatContext/ChatMessageContext.tsx";

/**
 * ChatBubble component
 * @param message - ChatMessage object
 * @returns ChatBubble component
 */
interface BotMessageProps {
    message: getMessages.Output[0];
}
export const ChatBubble: FC<BotMessageProps> = ({ message }) => {
    const { user } = useAuth();

    // Extract source ids from message content
    const sources = aggregateSources(message.content);

    return (
        // Bubble background (both for bot and user)
        <HStack
            p={4}
            borderRadius={"3xl"}
            border={message.is_user ? "1px" : ""}
            borderColor={message.is_user ? "gray.300" : ""}
            bg={message.is_user ? "white" : "gray.200"}
            align={"start"}
        >
            {/* avatar in bubble */}

            {message.is_user && <Avatar borderRadius="lg" size={"xs"} src={user?.profile.picture} />}
            {!message.is_user && <Avatar borderRadius="lg" size={"xs"} src={MaeveAvatar} />}

            {/* wraps content inside of a bubble (author + content + copy button) */}
            <Stack gap={4} flexGrow={1}>
                <HStack h={6} justify={"space-between"}>
                    {/* Name of author of bubble / message */}
                    <Text color={message.is_user ? "purple.500" : "black.500"} variant={"labelBold"}>
                        {message.is_user ? user?.profile.name : "Maeve"}
                    </Text>

                    {/*Context (assets used)*/}
                    {message.contexts.length > 0 && (
                        <ChatMessageContext caseId={message.case_id} contexts={message.contexts} />
                    )}
                </HStack>
                {/* skeleton loader */}
                {message.content.length === 0 && <Skeleton height={2} />}
                <>
                    {/* actual message content */}
                    <StyledMarkdown>{message.content}</StyledMarkdown>

                    {/* @todo: extract this out */}
                    {!message.is_user && (
                        <HStack flexWrap={"wrap"} justify={"space-between"}>
                            {sources.length > 0 && (
                                <SourcesPopover heading={"Sources"} chunkIds={sources} trigger={"hover"}>
                                    {/* show all sources button */}
                                    <Button
                                        bg={"gray.300"}
                                        _hover={{ bg: "gray.400" }}
                                        aria-label={"all source citation button"}
                                        leftIcon={<Icon as={FontAwesomeIcon} icon={faQuoteRight} />}
                                    >
                                        Show all sources
                                    </Button>
                                </SourcesPopover>
                            )}

                            {/* Spacer to push copy button to the right */}
                            <Spacer />

                            {/*copy to clipboard button*/}
                            <CopyToClipboard content={message.content} />
                        </HStack>
                    )}
                </>
                );
            </Stack>
        </HStack>
    );
};
