import { useQuery } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useQuestionnairesQuery = (caseId: number) => {
    return useQuery({
        queryKey: ["questionnaires", caseId],
        queryFn: async () => {
            return await sdk.functional.$case.questionnaires.getQuestionnaires(getApiConnection(), caseId);
        },
    });
};
