import { Box, Button, Center, Icon, Image, Spacer, Stack } from "@chakra-ui/react";
import { faClipboard, faCommentDots, faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import maeveLogoTriangle from "../../../assets/logos/maeve-logo-triangle.svg";
import maeveLogo from "../../../assets/logos/maeve-logo.svg";
import { CaseDropdown } from "../sideNav/caseDropdown/CaseDropdown.tsx";
import { NotificationsPopover } from "../sideNav/notificationsPopover/NotificationsPopover.tsx";
import { UserPopover } from "../sideNav/userPopover/UserPopover.tsx";

interface SideNavContentProps {
    expanded: boolean;
}
export const SideNavContent: FC<SideNavContentProps> = ({ expanded }) => {
    const { caseId: caseIdStr } = useParams();
    const caseId = parseInt(caseIdStr || "");
    const path = useLocation().pathname;

    return (
        <Box
            transitionProperty={"width"}
            transitionTimingFunction={"ease-in-out"}
            transitionDuration="350ms"
            h="full"
            p="4"
            w={expanded ? "280px" : "88px"}
            overflow={"hidden"}
        >
            <Stack gap={12} justifyContent="flex-start" h="full" alignItems="stretch">
                {/*Only show case picker and links when a case has been selected*/}
                {caseId && (
                    <>
                        <CaseDropdown />

                        <Stack>
                            {/*{expanded && (*/}
                            {/*    <Text align="start" mb={3} variant="labelCaps">*/}
                            {/*        Case*/}
                            {/*    </Text>*/}
                            {/*)}*/}

                            {/* Menu/page buttons */}
                            {/* Company info button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}`}
                                isActive={path === `/cases/${caseId}`}
                                variant={"menuButton"}
                                iconSpacing="4"
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faAlignLeft} />}
                            >
                                {expanded && "Company info"}
                            </Button>
                            {/* Assets button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/assets`}
                                isActive={path === `/cases/${caseId}/assets`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faPaperclip} />}
                            >
                                {expanded && "Assets"}
                            </Button>

                            {/* Memo button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/memo`}
                                isActive={path === `/cases/${caseId}/memo`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faFileLines} />}
                            >
                                {expanded && "Memo"}
                            </Button>

                            {/*Q&A button*/}
                            {/*<Button*/}
                            {/*    as={Link}*/}
                            {/*    to={`/cases/${caseId}/questionnaires`}*/}
                            {/*    isActive={path === `/cases/${caseId}/questionnaires`}*/}
                            {/*    variant={"menuButton"}*/}
                            {/*    iconSpacing={4}*/}
                            {/*    leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faClipboard} />}*/}
                            {/*>*/}
                            {/*    {expanded && "Q&A"}*/}
                            {/*</Button>*/}

                            {/* Maeve chat button */}
                            <Button
                                as={Link}
                                to={`/cases/${caseId}/chat`}
                                isActive={path === `/cases/${caseId}/chat`}
                                variant={"menuButton"}
                                iconSpacing={4}
                                leftIcon={<Icon size={"xl"} as={FontAwesomeIcon} icon={faCommentDots} />}
                            >
                                {expanded && "Maeve"}
                            </Button>
                        </Stack>
                    </>
                )}
                <Spacer />

                <Stack gap={4}>
                    {/* Notifications button */}
                    <NotificationsPopover expanded={expanded} />

                    {/* User name + logout button */}
                    <UserPopover expanded={expanded} />

                    <Spacer />
                    {/* Maeve logo */}
                    <Center>
                        <Image src={expanded ? maeveLogo : maeveLogoTriangle} alt="Maeve logo" />
                    </Center>
                </Stack>
            </Stack>
        </Box>
    );
};
