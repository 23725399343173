import { FC } from "react";
import { useCreateQuestionMutation } from "../../../lib/mutations/useCreateQuestionMutation.ts";
import { usePatchQuestionMutation } from "../../../lib/mutations/usePatchQuestionMutation.ts";
import { getQuestions } from "../../../sdk/functional/$case/questionnaires/questions";
import { Question } from "./Question.tsx";

interface Props {
    index?: number;
    caseId: number;
    questionnaireId: number;
    question?: getQuestions.Output[number];
}
export const QuestionForm: FC<Props> = ({ index, caseId, questionnaireId, question }) => {
    const $createQuestion = useCreateQuestionMutation();
    const $patchQuestion = usePatchQuestionMutation(question?.id);

    const save = async (string: string) => {
        if (!question) $createQuestion.mutate({ caseId, questionnaireId, question: string });
        else $patchQuestion.mutate({ caseId, questionnaireId, question: string });
    };

    return (
        <Question
            question={question?.question ?? ""}
            reasoning={question?.reasoning ?? undefined}
            index={index}
            onEdit={save}
        />
    );
};
