import { Link, Tag, TagCloseButton, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useAssetUrlQuery } from "../../../lib/queries/useAssetUrlQuery.ts";
import { GetMessagesDto } from "../../../sdk/structures/GetMessagesDto.ts";

interface Props {
    asset: GetMessagesDto["contexts"][0]["asset"];
    asLink?: boolean;
    onRemove?: () => void;
}

/**
 * Renders a single context in a tag
 * @param asset
 * @param asLink
 * @param onRemove
 */
export const Context: FC<Props> = ({ asset, asLink, onRemove }) => {
    const $url = useAssetUrlQuery(asset.case_id, asset.id);

    const tag = (
        <Tag
            variant={"asset"}
            size={"md"}
            gap={0}
            _hover={{
                bg: asLink ? "gray.400" : undefined,
            }}
            maxW={"200"}
            wordBreak={"break-all"}
        >
            {asLink && <TagLeftIcon as={FontAwesomeIcon} icon={faLink} boxSize={4}></TagLeftIcon>}
            <TagLabel>{asset.filename}</TagLabel>
            {onRemove && <TagCloseButton onClick={() => onRemove()} />}
        </Tag>
    );

    if (asLink) {
        return (
            <Link href={$url.data} target={"_blank"}>
                {tag}
            </Link>
        );
    }

    return tag;
};
