/**
 * @packageDocumentation
 * @module api.functional.$case.questionnaires.questions.generate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller QuestionController.generateQuestions
 * @path POST /case/:caseId/questionnaires/:questionnaireId/questions/generate
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function generateQuestions(
    connection: IConnection,
    caseId: number,
    questionnaireId: number,
): Promise<generateQuestions.Output> {
    return PlainFetcher.fetch(connection, {
        ...generateQuestions.METADATA,
        template: generateQuestions.METADATA.path,
        path: generateQuestions.path(caseId, questionnaireId),
    });
}
export namespace generateQuestions {
    export type Output = any;

    export const METADATA = {
        method: "POST",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions/generate",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, questionnaireId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions/generate`;
}
