import {
    Button,
    Checkbox,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { faMagnifyingGlass, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { FC, PropsWithChildren, useMemo, useState } from "react";
import { useAssetsQuery } from "../../../lib/queries/useAssetsQuery.ts";
import { getAssets } from "../../../sdk/functional/asset/$case/assets";

interface ChatContextPopoverProps extends PropsWithChildren {
    caseId: number;
    onClear: () => void;
    selectedAssetIds: number[];
    setSelectedAssetIds: (ids: number[]) => void;
}
export const ChatContextPopover: FC<ChatContextPopoverProps> = ({
    caseId,
    onClear,
    selectedAssetIds,
    setSelectedAssetIds,
}) => {
    const $assets = useAssetsQuery(caseId);

    // Search logic
    const [query, setQuery] = useState("");
    const fuse = useMemo(
        () => new Fuse($assets.data ?? ([] as getAssets.Output), { keys: ["filename"] }),
        [$assets.data],
    );
    const filteredAssets = query.length > 0 ? fuse.search(query).map((r) => r.item) : ($assets.data ?? []);

    return (
        <Popover placement={"bottom-start"}>
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button variant={"unstyled"}>
                            <Tag variant={"asset"} size={"md"} _hover={{ bg: "gray.400" }}>
                                <Icon as={FontAwesomeIcon} icon={faPlus} />
                                <TagLabel>Select context</TagLabel>
                            </Tag>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent w={400}>
                        <PopoverArrow />
                        <PopoverHeader>
                            {/*Title, clear*/}
                            <HStack>
                                <Text variant={"title"}>Context</Text>
                                <Spacer />
                                <Button
                                    color={"purple.500"}
                                    variant={"link"}
                                    onClick={() => {
                                        onClose();
                                        setQuery("");
                                        onClear();
                                    }}
                                >
                                    Clear
                                </Button>
                            </HStack>

                            {/*Subtitle*/}
                            <Text variant={"body2"} color={"gray.600"}>
                                Select asset(s) to use for response
                            </Text>
                        </PopoverHeader>

                        <PopoverBody>
                            <Stack gap={6}>
                                {/*Search input*/}
                                <InputGroup variant={"ghost"}>
                                    <InputLeftElement pointerEvents="none">
                                        <Icon icon={faMagnifyingGlass} as={FontAwesomeIcon} />
                                    </InputLeftElement>
                                    <Input
                                        variant={"gray"}
                                        placeholder={"Search assets"}
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            variant={"unstyled"}
                                            aria-label={"Clear search"}
                                            icon={<Icon as={FontAwesomeIcon} icon={faTimes} />}
                                            onClick={() => setQuery("")}
                                        />
                                    </InputRightElement>
                                </InputGroup>

                                {/*No results*/}
                                {filteredAssets.length === 0 && $assets.data && $assets.data?.length > 0 && (
                                    <Text variant={"body2"} color={"gray.600"}>
                                        No results found
                                    </Text>
                                )}

                                {/*No assets uploaded*/}
                                {filteredAssets.length === 0 && $assets.data && $assets.data?.length === 0 && (
                                    <Text variant={"body2"} color={"gray.600"}>
                                        No assets uploaded
                                    </Text>
                                )}

                                {/*Search results, scrollable*/}
                                {filteredAssets.length > 0 && (
                                    <Stack maxH={230} overflowY={"auto"} pr={4}>
                                        {filteredAssets.map((asset) => (
                                            <HStack key={asset.id}>
                                                {/*Checkbox per asset*/}
                                                <Checkbox
                                                    size={"lg"}
                                                    colorScheme={"purple"}
                                                    isChecked={selectedAssetIds.includes(asset.id)}
                                                    isDisabled={asset.index_pending}
                                                    onChange={() => {
                                                        if (selectedAssetIds.includes(asset.id)) {
                                                            setSelectedAssetIds(
                                                                selectedAssetIds.filter((id) => id !== asset.id),
                                                            );
                                                        } else {
                                                            setSelectedAssetIds([...selectedAssetIds, asset.id]);
                                                        }
                                                    }}
                                                >
                                                    <Text
                                                        noOfLines={1}
                                                        wordBreak={"break-all"}
                                                        variant={"body2"}
                                                        fontWeight={"semibold"}
                                                    >
                                                        {asset.filename}
                                                    </Text>
                                                </Checkbox>
                                            </HStack>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};
