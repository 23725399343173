/**
 * @packageDocumentation
 * @module api.functional.$case.questionnaires.questions.accept
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

/**
 * @controller QuestionController.acceptQuestions
 * @path POST /case/:caseId/questionnaires/:questionnaireId/questions/accept
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function acceptQuestions(
    connection: IConnection,
    caseId: number,
    questionnaireId: number,
    data: acceptQuestions.Input,
): Promise<void> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...acceptQuestions.METADATA,
            template: acceptQuestions.METADATA.path,
            path: acceptQuestions.path(caseId, questionnaireId),
        },
        data,
    );
}
export namespace acceptQuestions {
    export type Input = {
        questionIds: number[];
    };

    export const METADATA = {
        method: "POST",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions/accept",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, questionnaireId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions/accept`;
}
