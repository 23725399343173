import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation } from "@tanstack/react-query";
import { getMessages } from "../../sdk/functional/bot/sessions/messages";
import { getAuthHeaders } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useSendChatMessageMutation = (setInputBlocked?: (blocked: boolean) => void) => {
    return useMutation({
        mutationFn: async ({
            sessionId,
            content,
            assetIds,
        }: {
            sessionId: number;
            content: string;
            assetIds?: number[];
        }) => {
            if (!content.trim().length) return;

            const onChatMessageAdd = (message: getMessages.Output[0]) => {
                queryClient.setQueryData<getMessages.Output>(["chatMessages", sessionId], (messages) => {
                    if (!messages) return [message];
                    return [...messages, message];
                });
            };

            const onChatMessageAppend = (data: { sessionId: number; messageId: number; content: string }) => {
                queryClient.setQueryData<getMessages.Output>(["chatMessages", data.sessionId], (messages) => {
                    if (!messages) return messages;
                    return messages.map((message) => {
                        if (message.id === data.messageId)
                            return { ...message, content: message.content + data.content };
                        return message;
                    });
                });
            };

            await fetchEventSource(import.meta.env.VITE_API_BASE + `/bot/sessions/${sessionId}/messages`, {
                method: "POST",
                headers: { "Content-Type": "application/json", ...getAuthHeaders() },
                body: JSON.stringify({ content, assetIds }),
                openWhenHidden: true, // critical so it doesnt re-post on tab focus
                onmessage: (msg: EventSourceMessage) => {
                    switch (msg.event) {
                        case "error":
                            throw new Error(msg.data);
                        case "chatMessage.add":
                            onChatMessageAdd(JSON.parse(msg.data));
                            break;
                        case "chatMessage.append":
                            onChatMessageAppend(JSON.parse(msg.data));
                            break;
                        case "chatInput.block":
                            setInputBlocked?.(JSON.parse(msg.data).block);
                            break;
                    }
                },
                onerror: (err: unknown) => {
                    console.warn("error", err);
                    throw err; // Stop fetchEventSource from retrying
                },
            });
        },
    });
};
