import { Button, Heading, HStack, Icon, IconButton, Spacer, Spinner, Stack, useDisclosure } from "@chakra-ui/react";
import { faArrowLeft, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Reorder } from "motion/react";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { ScrollBox } from "../../components/navigation/scrollBox/ScrollBox.tsx";
import { useDeletePendingQuestionsMutation } from "../../lib/mutations/useDeletePendingQuestionsMutation.ts";
import { useQuestionnaireQuery } from "../../lib/queries/useQuestionnaireQuery.ts";
import { useQuestionsQuery } from "../../lib/queries/useQuestionsQuery.ts";
import { getQuestions } from "../../sdk/functional/$case/questionnaires/questions";
import { QuestionForm } from "./components/QuestionForm.tsx";
import { QuestionnaireSidebar } from "./components/QuestionnaireSidebar.tsx";

interface Props {}
export const QuestionnairePage: FC<Props> = ({}) => {
    const { caseId } = useParams<"caseId">();
    const { questionnaireId } = useParams<"questionnaireId">();
    if (!caseId) throw new Error("No case id provided");
    if (!questionnaireId) throw new Error("No questionnaire id provided");

    const aiPanel = useDisclosure();

    const $questionnaire = useQuestionnaireQuery(parseInt(caseId), parseInt(questionnaireId));
    const $questions = useQuestionsQuery(parseInt(caseId), parseInt(questionnaireId));
    const $deletePendingQuestions = useDeletePendingQuestionsMutation(parseInt(caseId), parseInt(questionnaireId));

    const [orderedQuestions, setOrderedQuestions] = useState<getQuestions.Output>([]);

    useEffect(() => {
        if (!$questions.data) return;
        setOrderedQuestions($questions.data);
    }, [$questions.data]);

    // Delete all pending on page load
    useEffect(() => {
        console.log("Deleting pending questions");
        $deletePendingQuestions.mutate();
    }, []);

    if ($questionnaire.isLoading || !$questionnaire.data) return <Spinner />;

    return (
        <SideNavLayout>
            <HStack gap={0} align="stretch" flex={1}>
                <Stack gap={5} flex={1} as={motion.div} layout>
                    {/*editable title, back button*/}
                    <HStack gap={4} flex={0}>
                        <IconButton
                            aria-label={"back button"}
                            size={"xl"}
                            bg={"none"}
                            p={3}
                            icon={<FontAwesomeIcon icon={faArrowLeft} />}
                        />
                        <Heading as={"h1"} size={"lg"}>
                            {$questionnaire.data.name}

                            {/*<Tooltip label={"Edit title"}>*/}
                            {/*    /!* edit button *!/*/}
                            {/*    <IconButton*/}
                            {/*        bg={"none"}*/}
                            {/*        aria-label={"edit title"}*/}
                            {/*        size={"lg"}*/}
                            {/*        icon={<FontAwesomeIcon icon={faPen} />}*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                        </Heading>
                    </HStack>

                    {/*Questions header + generate button*/}
                    <HStack>
                        <Heading as={"h4"} variant={"h2"}>
                            Questions
                        </Heading>
                        <Spacer />

                        <Button
                            leftIcon={<Icon as={FontAwesomeIcon} icon={faWandMagicSparkles} />}
                            onClick={() => aiPanel.onToggle()}
                            visibility={aiPanel.isOpen ? "hidden" : "visible"}
                        >
                            Generate questions
                        </Button>
                    </HStack>

                    {/* Questions */}
                    <ScrollBox hideScrollbar={true} flex={1} fadeColor={"#ffffff"}>
                        <Reorder.Group
                            as={"div"}
                            values={$questions.data ?? []}
                            onReorder={(newData) => setOrderedQuestions(newData)}
                            onDragEnd={() => {
                                console.log("Todo: save order");
                                // saveClipOrdinal(clip.id!);
                            }}
                        >
                            {orderedQuestions.map((question, i) => (
                                <Reorder.Item
                                    as={"div"}
                                    key={question.id}
                                    value={question}
                                    style={{ marginBottom: 10, cursor: "grab" }}
                                    whileDrag={{
                                        cursor: "grabbing",
                                    }}
                                >
                                    {/*<Question*/}
                                    {/*    question={question.question}*/}
                                    {/*    reasoning={question.reasoning ?? undefined}*/}
                                    {/*    index={i}*/}
                                    {/*/>*/}

                                    <QuestionForm
                                        index={i}
                                        caseId={parseInt(caseId)}
                                        questionnaireId={parseInt(questionnaireId)}
                                        question={question}
                                        key={question.id + question.question}
                                    />
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                        <QuestionForm
                            index={$questions.data?.length}
                            caseId={parseInt(caseId)}
                            questionnaireId={parseInt(questionnaireId)}
                        />
                    </ScrollBox>

                    {/*</Container>*/}
                </Stack>

                <Stack
                    alignSelf={"stretch"}
                    as={motion.div}
                    variants={{
                        open: { width: "auto", overflow: "visible", transition: { ease: "easeInOut" } },
                        closed: { width: 0, overflow: "hidden", transition: { ease: "easeInOut" } },
                    }}
                    animate={aiPanel.isOpen ? "open" : "closed"}
                    initial={"closed"}
                    overflow={"hidden"}
                >
                    <QuestionnaireSidebar
                        caseId={parseInt(caseId)}
                        questionnaireId={parseInt(questionnaireId)}
                        onClose={() => aiPanel.onClose()}
                        isOpen={aiPanel.isOpen}
                    />
                </Stack>
            </HStack>
        </SideNavLayout>
    );
};
