/**
 * @packageDocumentation
 * @module api.functional.notification
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";

import type {
    caseidnumbernamestringlogo_urlstringnullnullfrom_useridnumbernamestringpicturestringnullnullidnumbercreated_atDateuser_idnumbercase_idnumbernullmessagestringfrom_user_idnumbernullis_readboolean
} from "../../structures/caseidnumbernamestringlogo_urlstringnullnullfrom_useridnumbernamestringpicturestringnullnullidnumbercreated_atDateuser_idnumbercase_idnumbernullmessagestringfrom_user_idnumbernullis_readboolean";

/**
 * @controller NotificationController.getNotifications
 * @path GET /notification
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getNotifications(connection: IConnection): Promise<getNotifications.Output> {
    return PlainFetcher.fetch(connection, {
        ...getNotifications.METADATA,
        template: getNotifications.METADATA.path,
        path: getNotifications.path(),
    });
}
export namespace getNotifications {
    export type Output =
        caseidnumbernamestringlogo_urlstringnullnullfrom_useridnumbernamestringpicturestringnullnullidnumbercreated_atDateuser_idnumbercase_idnumbernullmessagestringfrom_user_idnumbernullis_readboolean[];

    export const METADATA = {
        method: "GET",
        path: "/notification",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/notification";
}

/**
 * @controller NotificationController.markAllRead
 * @path PATCH /notification/markAllRead
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function markAllRead(connection: IConnection): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...markAllRead.METADATA,
        template: markAllRead.METADATA.path,
        path: markAllRead.path(),
    });
}
export namespace markAllRead {
    export const METADATA = {
        method: "PATCH",
        path: "/notification/markAllRead",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/notification/markAllRead";
}

/**
 * @controller NotificationController.deleteNotification
 * @path DELETE /notification/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteNotification(connection: IConnection, notificationId: number): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...deleteNotification.METADATA,
        template: deleteNotification.METADATA.path,
        path: deleteNotification.path(notificationId),
    });
}
export namespace deleteNotification {
    export const METADATA = {
        method: "DELETE",
        path: "/notification/:id",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (notificationId: number) => `/notification/${encodeURIComponent(notificationId ?? "null")}`;
}

/**
 * @controller NotificationController.deleteAll
 * @path DELETE /notification
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteAll(connection: IConnection): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...deleteAll.METADATA,
        template: deleteAll.METADATA.path,
        path: deleteAll.path(),
    });
}
export namespace deleteAll {
    export const METADATA = {
        method: "DELETE",
        path: "/notification",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = () => "/notification";
}
