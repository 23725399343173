import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useCreateQuestionnareMutation = () => {
    return useMutation({
        mutationFn: async ({ caseId }: { caseId: number }) => {
            const questionnaire = await sdk.functional.$case.questionnaires.createQuestionnaire(
                getApiConnection(),
                caseId,
            );

            return questionnaire;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["questionnaires"]);
        },
    });
};
