import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useCreateQuestionMutation = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: async ({
            caseId,
            questionnaireId,
            question,
        }: {
            caseId: number;
            questionnaireId: number;
            question: string;
        }) => {
            return await sdk.functional.$case.questionnaires.questions.createQuestion(
                getApiConnection(),
                questionnaireId,
                caseId,
                { question },
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["questions"]);
            toast({
                title: "Question added",
                status: "success",
            });
        },
    });
};
