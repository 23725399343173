import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    HStack,
    SkeletonCircle,
    SkeletonText,
    Spacer,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    Text,
} from "@chakra-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAxiosError } from "axios";
import { useAtom } from "jotai/react";
import { atomWithStorage } from "jotai/utils";
import { FC } from "react";
import BuildingImage from "../../../assets/images/building.svg";
import { useSimilarCompaniesQuery } from "../../../lib/queries/useSimilarCompaniesQuery.ts";
import { SimilarCompaniesQuery } from "../../../sdk/structures/SimilarCompaniesQuery.ts";
import { CompanyCard } from "../../case/companyCard/CompanyCard.tsx";
import { EmptyState } from "../../misc/emptyState/EmptyState.tsx";
import { AgeFilter } from "../ageFilter/AgeFilter.tsx";
import { EmployeesFilter } from "../employeesFilter/EmployeesFilter.tsx";
import { LocationFilter } from "../locationFilter/LocationFilter.tsx";
import { SortingFilter } from "../sortingFilter/SortingFilter.tsx";

const filtersAtom = atomWithStorage<SimilarCompaniesQuery>(
    "similarCompaniesFilters",
    {
        minAge: undefined,
        maxAge: undefined,
        minEmployees: undefined,
        maxEmployees: undefined,
        sort: "relevance",
    },
    undefined,
    {
        getOnInit: true,
    },
);

interface SimilarCompaniesProps {
    caseId: number;
}
export const SimilarCompanies: FC<SimilarCompaniesProps> = ({ caseId }) => {
    const [filters, setFilters] = useAtom(filtersAtom);
    const { data, isLoading, isSuccess, isError, error } = useSimilarCompaniesQuery(caseId, filters);

    // todo: ignore sort!
    const hasCustomFilters = Object.values({ ...filters, sort: undefined }).some((value) => value !== undefined);

    const resetFilters = () => setFilters({ sort: "relevance" });

    return (
        <Card variant={"subtle"}>
            {/* card with similar companies & header/title */}
            <CardHeader>
                <HStack>
                    {/* similar companies title */}
                    <Heading as={"h2"} variant={"h2"}>
                        Similar companies
                    </Heading>
                    <Spacer />
                    {/* loading chip/spinner */}
                    {isLoading && (
                        <Tag variant={"loader"}>
                            <Spinner color={"purple.500"} speed={"1s"} size="xs" />
                            <TagLabel>Looking for similar companies</TagLabel>
                        </Tag>
                    )}
                </HStack>
            </CardHeader>
            <CardBody>
                {/* sorting filters here */}
                <HStack pb={7} gap={3}>
                    <AgeFilter
                        minAge={filters.minAge}
                        maxAge={filters.maxAge}
                        onChange={(minAge?: number, maxAge?: number) => setFilters({ ...filters, minAge, maxAge })}
                    />
                    <EmployeesFilter
                        minEmployees={filters.minEmployees}
                        maxEmployees={filters.maxEmployees}
                        onChange={(minEmployees?: number, maxEmployees?: number) =>
                            setFilters({ ...filters, minEmployees, maxEmployees })
                        }
                    />
                    <LocationFilter
                        onChange={(continent, country) => setFilters({ ...filters, continent, country })}
                        continent={filters.continent}
                        country={filters.country}
                    />
                    {hasCustomFilters && (
                        <Button
                            variant={"ghost"}
                            onClick={() => resetFilters()}
                            leftIcon={<FontAwesomeIcon icon={faXmark} />}
                        >
                            Clear filters
                        </Button>
                    )}
                    <Spacer />

                    {/* Sort by filter */}
                    <SortingFilter
                        sort={filters.sort}
                        onChange={(sort: SimilarCompaniesQuery["sort"]) => setFilters({ ...filters, sort })}
                    />
                </HStack>
                {/* similar companies list */}
                <Stack gap={4}>
                    {/* loading state for similar companies */}
                    {isLoading && (
                        <>
                            <Card shadow={"none"}>
                                <HStack gap={6}>
                                    <SkeletonCircle size={"16"} borderRadius={"xl"} />
                                    <SkeletonText noOfLines={2} spacing={4} w={"50%"} />
                                    <Spacer />
                                    {/* Button skeleton  */}
                                    <SkeletonCircle borderRadius={"lg"} size={"8"} />
                                </HStack>
                            </Card>
                            <Card shadow={"none"}>
                                <HStack gap={6}>
                                    <SkeletonCircle size={"16"} borderRadius={"xl"} />
                                    <SkeletonText noOfLines={2} spacing={4} w={"50%"} />
                                    <Spacer />
                                    {/* Button skeleton  */}
                                    <SkeletonCircle borderRadius={"lg"} size={"8"} />
                                </HStack>
                            </Card>
                            <Card shadow={"none"}>
                                <HStack gap={6}>
                                    <SkeletonCircle size={"16"} borderRadius={"xl"} />
                                    <SkeletonText noOfLines={2} spacing={4} w={"50%"} />
                                    <Spacer />
                                    {/* Button skeleton  */}
                                    <SkeletonCircle borderRadius={"lg"} size={"8"} />
                                </HStack>
                            </Card>
                        </>
                    )}

                    {isSuccess && (
                        <Stack gap={4}>
                            {/* list of similar company cards */}
                            {data?.map((company) => (
                                // Individual similar company card
                                <CompanyCard key={company.id} company={company} />
                            ))}
                        </Stack>
                    )}

                    {
                        // empty state
                        !isLoading && isSuccess && data?.length === 0 && (
                            <EmptyState imageHeight={"58px"} imageSrc={BuildingImage} py={24}>
                                No companies found. Try changing your filters.
                            </EmptyState>
                        )
                    }

                    {isError && <Text>{isAxiosError(error) ? error.message : "Something went wrong"}</Text>}
                </Stack>
            </CardBody>
        </Card>
    );
};
