/**
 * @packageDocumentation
 * @module api.functional.$case.questionnaires.questions.generated
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

/**
 * @controller QuestionController.getGeneratedQuestions
 * @path GET /case/:caseId/questionnaires/:questionnaireId/questions/generated
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getGeneratedQuestions(
    connection: IConnection,
    questionnaireId: number,
    caseId: number,
): Promise<getGeneratedQuestions.Output> {
    return PlainFetcher.fetch(connection, {
        ...getGeneratedQuestions.METADATA,
        template: getGeneratedQuestions.METADATA.path,
        path: getGeneratedQuestions.path(questionnaireId, caseId),
    });
}
export namespace getGeneratedQuestions {
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        question: string;
        generated: boolean;
        reasoning: null | string;
        accepted: null | boolean;
        questionnaire_id: number;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions/generated",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (questionnaireId: number, caseId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions/generated`;
}
