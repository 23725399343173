import { Avatar, Card, HStack, Icon, IconButton, Stack, Text, Tooltip } from "@chakra-ui/react";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useRemoveMember } from "../../../lib/mutations/useRemoveMember.ts";
import { useCaseQuery } from "../../../lib/queries/useCaseQuery.ts";
import { useCurrentUserQuery } from "../../../lib/queries/useCurrentUserQuery.ts";
import { getMembers } from "../../../sdk/functional/$case/member";

interface MemberCardProps {
    caseId: number;
    member: getMembers.Output[0];
}
export const MemberCard: FC<MemberCardProps> = ({ caseId, member }) => {
    // Queries
    const { data: kees } = useCaseQuery(caseId);
    const { data: me } = useCurrentUserQuery();

    // Mutations
    const { mutate: removeMember, isLoading: isRemoveMemberLoading } = useRemoveMember(caseId);

    // Logic to determine if the user is the owner
    const isOwner = (userId: number) => userId === kees?.user_id;
    // Logic to determine if the user is the current user
    const isMe = (userId: number) => userId === me?.id;
    // Logic to determine if a member can be removed
    const canRemoveMember = (userId: number) => {
        if (isMe(userId)) return false;
        if (isOwner(userId)) return false;
        return true;
    };

    return (
        // Individual member card
        <Card p={2} key={member.id} variant={"flat"} size={"sm"}>
            <HStack key={member.id} gap={3}>
                <Avatar src={member.user.picture || undefined} name={member.user.name} />
                <Stack gap={0} flexGrow={1}>
                    <Text fontWeight={"bold"} fontSize={"sm"} flexGrow={1}>
                        {member.user.name}
                    </Text>

                    <Text fontSize={"sm"} flexGrow={1}>
                        {member.user.email}
                    </Text>
                </Stack>

                {/* Owner */}
                {isOwner(member.user_id) && (
                    <Text variant={"body2"} color={"gray.600"} mr={3}>
                        Owner
                    </Text>
                )}

                {/* You */}
                {isMe(member.user_id) && (
                    <Text variant={"body2"} color={"gray.600"} mr={3}>
                        You
                    </Text>
                )}

                {/* remove member button */}
                {canRemoveMember(member.user_id) && (
                    <Tooltip placement={"bottom"} hasArrow label="Remove member" aria-label="A tooltip">
                        <IconButton
                            aria-label={"Remove member"}
                            variant={"ghost"}
                            isDisabled={!canRemoveMember(member.user_id)}
                            isLoading={isRemoveMemberLoading}
                            onClick={() => {
                                if (!confirm(`Are you sure you want to remove ${member.user.name}?`)) return;
                                removeMember(member.id);
                            }}
                            color={"gray.600"}
                            // icon={<Icon color={"gray.600"} fontSize={"xl"} as={FontAwesomeIcon} icon={faCircleXmark} />}
                            icon={<Icon boxSize={5} as={FontAwesomeIcon} icon={faCircleXmark} />}
                        />
                    </Tooltip>
                )}
            </HStack>
        </Card>
    );
};
