import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../sdk";
import { getApiConnection } from "../app/api.ts";

export const useAcceptQuestionsMutation = (caseId: number, questionnaireId: number) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ questionIds }: { questionIds: number[] }) => {
            await api.functional.$case.questionnaires.questions.accept.acceptQuestions(
                getApiConnection(),
                caseId,
                questionnaireId,
                { questionIds },
            );
        },
        onSuccess: () => {
            // Invalidate generated questions query to refetch with updated read status
            queryClient.invalidateQueries({ queryKey: ["generatedQuestions", caseId, questionnaireId] });
            queryClient.invalidateQueries({ queryKey: ["questions", caseId, questionnaireId] });
        },
    });
};
